import React from 'react'
import Card from 'react-bootstrap/Card'


const AssortmentOverviewCard = ({imageUrl, name, navUri, history}) => {

    return <Card>
        <Card.Img variant="top" src={imageUrl} style={{padding: 10}}/>
        <Card.Body className='text-center'
                   style={{fontSize: '0.8rem'}}
                   onClick={() => {
            history.push(`/assortiment/${navUri}`)
        }}>
            {name}
        </Card.Body>
    </Card>
}

export default AssortmentOverviewCard
