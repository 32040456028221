const colors = {
    purple: '#8D4373',
    green: '#768775',
    white: '#FFF'
}

const theme = {
    colors
}

export default theme
