import {StyleSheet} from 'aphrodite'
import theme from '../../utils/theme'

export default StyleSheet.create({
  card: {
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      transition: '0.3s',
      borderRadius: 5
  },
    link: {
      textDecoration: 'none',
        color: theme.colors.purple
    }
})
