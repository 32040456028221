import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import {Link} from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import bakeryLogo from '../../assets/images/general/stevers-bakery-logo-1.svg'
import styles from './styles'
import {css} from 'aphrodite/no-important'
import assortmentImage from '../../assets/images/general/home-page-assortment.jpg'
import webshopImage from '../../assets/images/general/webshop.jpg'

const HomePage = () => {
    return (<Container>
        <Row className="justify-content-center">
            <Col>
            </Col>
            <Col className={'col-3'}>
                <Image src={bakeryLogo} rounded style={{width: '100%'}}/>
            </Col>
            <Col>
            </Col>
        </Row>
        <Row className="justify-content-around">
            <Col className='col-6'>
                <Link to={'/assortiment'}>
                <Card>
                    <Card.Img variant="top" src={assortmentImage} style={{padding: 7}}/>
                    <Card.Body className={'text-center'}>
                        <Link to={'/assortiment'} className={[css(styles.link)]}>
                            Assortiment
                        </Link>
                    </Card.Body>
                </Card>
                </Link>

            </Col>

            <Col className='col-6'>
                <Link to={'/webshop'}>
                <Card>
                    <Card.Img variant="top" src={webshopImage} style={{padding: 7}}/>
                    <Card.Body className={'text-center'}>
                        <Link to={'/webshop'} className={[css(styles.link)]}>
                            Webshop
                        </Link>
                    </Card.Body>
                </Card>
                </Link>
            </Col>
        </Row>
    </Container>)
}

export default HomePage
