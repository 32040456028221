import React from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom"
import HomePage from './pages/HomePage'
import Assortiment from './pages/Assortment'
import Webshop from './pages/Webshop'


function App() {

    return (
        <Router>
            <Switch>
                <Route path="/" component={HomePage} exact={true}/>
                <Route path="/assortiment" component={Assortiment} exact={true}/>
                <Route path="/webshop" component={Webshop} exact={true}/>
                <Route path='/assortiment/:assortmentType' component={Assortiment} exact={true}/>
                <Route path="*" render={() => <Redirect to={'/'}/>}/>
            </Switch>
        </Router>
    )
}

export default App
