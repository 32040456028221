import {StyleSheet} from 'aphrodite'
import theme from '../../utils/theme'

export default StyleSheet.create({
    homeButton: {
        backgroundColor: theme.colors.green,
        borderRadius: 5,
        color: theme.colors.white,
        height: 40,
        width: 40,
        border: 'transparent'
    }
})
