import React from 'react'
import {css} from 'aphrodite/no-important'
import s from './styles'

const CarouselButton =  ({direction, handleDirection}) => {
    const styles = s(direction)

    const arrowDirection = direction === 'forwards' ? '>' : direction === 'backwards' ? '<' : ''

    return <button className={[css(styles.carouselBtn)]}
        onClick={() => handleDirection(direction)}
    >
        {arrowDirection}
    </button>
}

export default CarouselButton

