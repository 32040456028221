import React from 'react'
import Iframe from 'react-iframe'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Webshop = ({history}) => {

    console.log('props,', history)
    return (<Container style={{height: '100vh', padding: 50}} fluid>
        <Row style={{height: '100%'}}>
            <Col>
                <button style={{
                    position: 'absolute',
                    zIndex: 999,
                    top: -15,
                    backgroundColor: '#8FBC8F',
                    borderRadius: 5,
                    color: '#FFF',
                }} onClick={() => history.push('/')}>
                    Home
                </button>
                <Iframe url={'https://www.steversbanket.nl/webshop'}
                        height={'100%'}
                        width={'100%'}
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"/>
            </Col>
        </Row>

    </Container>)
}

export default Webshop
