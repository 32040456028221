import React, {Component} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import {cakeAssortment, assortment} from '../../constants'
import AssortmentOverviewCard from '../../components/AssortmentOverviewCard'
import CarouselButton from '../../components/CarouselButton'
import HomeButton from '../../components/HomeButton'
import bakeryLogo from '../../assets/images/general/stevers-bakery-logo-1.svg'


class Assortment extends Component {

    state = {
        selectedAssortmentIndex: 0,
        dividedAssortment:       [],
        currentAssortmentType: ''
    }


    componentDidMount() {

        // Check if the params is empty, if the are, this means that the user is in the root assortment view

        const dividedAssortment = this.divideAssortment(cakeAssortment, 8)
        const root = Object.entries(this.props.match.params).length === 0 && this.props.match.params.constructor === Object

        this.setState({dividedAssortment, currentAssortmentType: root ? 'default' : ''})
    }

    componentDidUpdate(prevProps, prevState) {


        const root = Object.entries(this.props.match.params).length === 0 && this.props.match.params.constructor === Object

        // Return if the user is in root overview
        if (root) return null

        // Return if the assortment type equels the set assortment type
        if(this.props.match.params.assortmentType === this.state.currentAssortmentType) return null

        const key = this.props.match.params.assortmentType

        const dividedAssortment = this.divideAssortment(assortment[key], 8)

        this.setState({dividedAssortment, currentAssortmentType: key})


    }

    divideAssortment = (assortment, chunkSize) => {

        if(!assortment) return []

        const dividedAssortment = []


        for (let i = 0; i < assortment.length; i += chunkSize) {
            dividedAssortment.push(assortment.slice(i, i + chunkSize))
        }


        return dividedAssortment

    }


    renderAssortmentRows = (assortment) => {


        const dividedAssortment = this.divideAssortment(assortment, 4)

        const firstRow = dividedAssortment[0]
        const secondRow = dividedAssortment[1]

        const {history} = this.props

        return (<React.Fragment>
            <Row className='justify-content-center' style={{marginLeft: 15, marginRight: 15, marginBottom: 15}}>
                {firstRow.map((cake, idx) => <Col key={idx}><AssortmentOverviewCard name={cake.name}
                                                                                    navUri={cake.navUri}
                                                                                    imageUrl={cake.imageUrl}
                                                                                    history={history}/></Col>)}
            </Row>

            {secondRow &&
            <Row className='justify-content-center' style={{marginLeft: 15, marginRight: 15, marginBottom: 15}}>
                {secondRow.map((cake, idx) => <Col key={idx}><AssortmentOverviewCard name={cake.name}
                                                                                     navUri={cake.navUri}
                                                                                     imageUrl={cake.imageUrl}
                                                                                     history={history}/></Col>)}
            </Row>}

        </React.Fragment>)
    }

    handleDirection = direction => {
        this.setState({
            selectedAssortmentIndex: direction === 'forwards' ? this.state.selectedAssortmentIndex + 1 : direction === 'backwards' ? this.state.selectedAssortmentIndex - 1 : this.state.selectedAssortmentIndex,
        })
    }


    directionIsValid = {
        assortmentIndexForwards:  () => !!(this.state.dividedAssortment[this.state.selectedAssortmentIndex + 1]),
        assortmentIndexBackwards: () => !!(this.state.dividedAssortment[this.state.selectedAssortmentIndex - 1]),

    }




    render() {


        const {dividedAssortment, selectedAssortmentIndex} = this.state
        const {history} = this.props

        if (dividedAssortment.length === 0) return <div>loading</div>

        const assortment = dividedAssortment[selectedAssortmentIndex]
        const forwardsIsValid = this.directionIsValid.assortmentIndexForwards()
        const backwardsIsValid = this.directionIsValid.assortmentIndexBackwards()

        return (
            <Container fluid style={{paddingLeft: 30, paddingRight: 30}}>
                {backwardsIsValid && <CarouselButton direction={'backwards'} handleDirection={this.handleDirection}/>}
                {forwardsIsValid && <CarouselButton direction={'forwards'} handleDirection={this.handleDirection}/>}
                <Row className={'align-items-center'}>
                    <Col>
                        <HomeButton history={history}/>
                    </Col>
                    <Col className='col-8'>

                    </Col>
                    <Col>
                        <Image src={bakeryLogo} rounded style={{height: 75}} fluid/>

                    </Col>
                </Row>
                <Row className='align-items-center justify-content-center'>
                    <Col>

                        Bread crumb
                    </Col>
                    <Col>

                        Paination
                    </Col>
                </Row>
                {this.renderAssortmentRows(assortment)}
            </Container>
        )
    }
}

export default Assortment
