import cakeImagePlaceholder from './assets/images/cake-example-1.jpg'

const assortment = {
    default: [
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Gebak',
            navUri: 'gebak'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Gebak klein',
            navUri: 'gebak-klein'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Sloffen en schnitten',
            navUri: 'sloffen-en-schnitten'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Taartjes',
            navUri: 'taartjes'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Taarten',
            navUri: 'taarten'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Gelegenheidstaarten',
            navUri: 'gelegenheidstaarten'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Hartig, koeken en cake',
            navUri: 'hartig-koeken-en-cake'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Koekjes',
            navUri: 'koekjes'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Chocolade en bonbons',
            navUri: 'chocolade-en-bon-bons'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Gebak',
            navUri: 'gebak'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Gebak',
            navUri: 'gebak'
        }
    ],
    gebak: [
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Gesorteerd gebak',
            navUri: 'gesorteerd-gebak'
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Vegan gebak',
            navUri: 'vegan-gebak',
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Glutenvrij gebak',
            navUri: 'glutenvrij-gebak',
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Lactose- en melkvrij gebak',
            navUri: 'lactose-en-melkvrij-gebak',
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Suikervrij gebak',
            navUri: 'suikervrij-gebak',
        },
        {
            imageUrl: cakeImagePlaceholder,
            name: 'Nootvrij gebak',
            navUri: 'nootvrij-gebak',
        },

    ]
}

const cakeAssortment = [
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Gebak',
        navUri: 'gebak'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Gebak klein',
        navUri: 'gebak-klein'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Sloffen en schnitten',
        navUri: 'sloffen-en-schnitten'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Taartjes',
        navUri: 'taartjes'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Taarten',
        navUri: 'taarten'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Gelegenheidstaarten',
        navUri: 'gelegenheidstaarten'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Hartig, koeken en cake',
        navUri: 'hartig-koeken-en-cake'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Koekjes',
        navUri: 'koekjes'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Chocolade en bonbons',
        navUri: 'chocolade-en-bon-bons'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Gebak',
        navUri: 'gebak'
    },
    {
        imageUrl: cakeImagePlaceholder,
        name: 'Gebak',
        navUri: 'gebak'
    }
]

export {
    cakeAssortment,
    assortment
}
